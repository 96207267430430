<!--
 * @Descripttion: 写日志
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-17 19:46:42
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-10-08 16:55:39
-->
<template>
  <el-scrollbar>
    <div class="write-journal-box" v-if="template.template_id">
      <p class="name" v-if="name !== '空白模板'">
        {{`${template && template.user_name}的${template && template.template_name}`}}
      </p>
      <div v-for="(data, index) in template && template.fields" :key="data.id" class="journal-item">
        <template v-if="name === '空白模板' && index === 0">
          <el-input class="title" type="textarea"
          :placeholder="`${template && template.user_name}的日志`"
          v-model="data.field_name"
          :rows="1"
          @focus="focusInput"
          > </el-input>
        </template>
        <p class="title" v-else> {{data.field_name}}：</p>
        <div class="content">
          <WriteEditorJournal :id="index" :content="data.content" v-if="data.type !== 'image' && data.type !== 'file'"></WriteEditorJournal>
          <!-- <el-input v-if="data.type !== 'image' && data.type !== 'file'"
          type="textarea" v-model="data.content"
          placeholder="请输入"
          :autosize="{ minRows: 1}"
          ></el-input> -->
          <template v-else-if="data.type === 'image'">
            <ImageInfo
              v-show="imageList.length > 0"
              ref="imageInfoRef"
              :limit="9 - imageList.length"
              :id="Number(new Date().getTime())"
              :draggable="true"
              size="small"
              ></ImageInfo>
            <UploadFile :limit="9" type="image" @uploadFileInf="uploadFileInf" v-if="imageList.length === 0">
              <template #mySlot>
                <div class="el-icon-plus border-6"></div>
              </template>
            </UploadFile>
          </template>
          <template v-else-if="data.type === 'file'">
            <FileList :fileList="fileList" :removeVisible="true" @removeFile="removeFile"></FileList>
            <UploadFile :limit="10 - fileList.length" type="file" @uploadFileInf="uploadFileInf" >
              <template #mySlot v-if="fileList.length < 10">
                <div class="el-icon-plus border-6"></div>
              </template>
            </UploadFile>
          </template>
        </div>
      </div>

      <div class="receive-wraper">
        <p class="desc">
          发送给：
          <el-checkbox v-model="noticeAgain" label="再次通知接收人" v-if="routeName === 'updateJournal'"></el-checkbox>
        </p>
        <div class="receive-list">
          <UserAvatar v-for="(user,index) in receiveList" :key="user.id" :user="{avatar: user.avatar, name: user.name, id: user.user_id || user.id }"
          size="40" fontSize="14" medalSize="55" :isPreview="true">
            <template #slot>
              <svg v-if="!user.disabled" class="remove-icon" @click="removeUser(index)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity=".5" cx="10" cy="10" r="10" fill="#666"/><rect x="12.829" y="5.757" width="2" height="10" rx="1" transform="rotate(45 12.829 5.757)" fill="#fff"/><rect x="14.243" y="12.828" width="2" height="10" rx="1" transform="rotate(135 14.243 12.828)" fill="#fff"/></svg>
              <span>{{user.name.length > 3 ? `${user.name.substr(0, 2)}...` : user.name}}</span>
            </template>
          </UserAvatar>

          <div class="el-icon-plus mini border-6" @click="showSelectUser"></div>
        </div>
      </div>
      <div class="button-wraper">
        <!-- 修改日志 -->
        <template v-if="routeName === 'updateJournal'">
          <el-button  type="primary" @click="submit(false)" :loading="isSubmiting">修改日志</el-button>
        </template>

        <!-- 创建日志 -->
        <template v-else>
          <el-button type="primary" @click="submit(true)"  v-show="isSubmitingWorkStatue" :loading="isSubmitingWork" >提交并签退</el-button>
          <el-button @click="submit(false)" :loading="isSubmiting">仅提交日志</el-button>
        </template>

      </div>
      <!-- 选择日志接收人弹窗 -->
      <el-dialog v-model="dialogVisible" custom-class="select-user-dialog"
        :width="651" title="日志接收人" :show-close="false" @close="hideSelectUser">
          <SelectUser ref="selectUserRef"
          :userIds="receiveUserIds"
          :disableUserIds="disableUserIds"
          :currentUser="currentUser"
          ></SelectUser>
          <template #footer>
            <div class="button-box">
              <el-button @click="hideSelectUser" type="primary" size="small">取消</el-button>
              <el-button type="primary" size="small" @click="addReceiveUser">确定</el-button>
            </div>
        </template>
      </el-dialog>
      <!-- 日志接收人异常提醒弹窗 -->
      <el-dialog v-model="dialogReceiverVisible" :show-close="false" center custom-class="receiver-dialog">
        <template #default>
          <div>日志接收人 <span class="name"> {{leaveUserName}} </span> 已离职，将该用户从接收人列表中移除</div>
        </template>
        <template #footer>
          <el-button size="small" @click="removeReceiveUser">确认</el-button>
        </template>
      </el-dialog>
    </div>
  </el-scrollbar>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { getTemplateInfo, addJournal, getReceiveList, getTeamUserList, getJournalInfo, updateJournal, setBuriedPoint } from '@/apis/journal.js'
import { dialogMsg, myToast } from '@/utils/dialogMsg.js'
import SelectUser from './SelectUser.vue'
import mySocket from '@/mixin/socket.js'
import WriteEditorJournal from './WriteEditorJournal'
import { addTableFatherNode } from '@/utils/filterHtml.js'
import { getAtUserID, debounce } from '@/utils/tools.js'
import { attendanceClock } from '@/apis/attendance.js'
import eventBus from '@/utils/eventBus.js'
import ImageInfo from '@/components/blog/ImageInfo.vue'

export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  components: { SelectUser, WriteEditorJournal, ImageInfo },
  setup (props) {
    onMounted(() => {
      state.routeName = route.name
      if (state.routeName === 'updateJournal') { // 修改日志
        getJournalData()
      }
      // 关闭页面保存数据
      // window.addEventListener('beforeunload', (e) => {
      //   if (!state.isSubmited && state.routeName !== 'updateJournal') {
      //     saveTempData()
      //   }
      // })
    })

    // 离开页面保存数据
    onBeforeRouteLeave(() => {
      // 已提交或者是修改日志离开页面，不保存数据
      !state.isSubmited && state.routeName !== 'updateJournal' && saveTempData()
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      template: { template_name: '' }, // 渲染的数据
      journalList: computed(() => store.state.work.journalList), // vuex中保存的临时数据
      receiveList: [],
      receiveUserIds: computed(() => { return state.receiveList.map(item => item.ding_user_id || item.userid) }),
      disableUserIds: computed(() => { return state.receiveList.filter(item => item.disabled).map(item => item.ding_user_id || item.userid) }),
      imageList: [],
      fileList: [],
      imageInfoRef: null,
      currentUser: computed(() => store.state.userInfo.userInfos),
      dialogVisible: false,
      selectUserRef: null,
      routeName: '',
      noticeAgain: false,
      leaveUser: [],
      leaveUserName: computed(() => { return state.leaveUser.map(item => item.name).join(',') }),
      dialogReceiverVisible: computed(() => { return state.leaveUser.length > 0 }),
      isSubmited: false,
      isSubmiting: false,
      writeEditorJournalRef: [],
      // nowJounalData: computed(() => localStorage.getItem('nowJounalData')) // 刷新页面保存的数据
      isSubmitingLog: false,
      isSubmitingWorkStatue: computed(() => (store.state.work.currentTime.split(':')[0] < 6 || store.state.work.currentTime.split(':')[0] >= 18) && store.state.work.clockState.sign_type !== 1),
      isSubmitingWork: false,
      count: 0
    })

    watch(() => props.name,
      (newVal, oldValue) => {
        if (state.routeName !== 'updateJournal' && state.template.template_id) {
          saveTempData()
        }
        nextTick(() => getTemplate(newVal))
      }
    )
    console.log(route.name)
    watch(() => state.receiveList, () => {
      // 接收人列表把自己挪到最前面
      if (!state.receiveList) return
      const dingUserId = state.currentUser.user_id
      const index = state.receiveList.findIndex(item => item.ding_user_id === dingUserId)
      if (index > -1) {
        const userSelf = state.receiveList[index]
        state.receiveList.splice(index, 1)
        state.receiveList.unshift(userSelf)
      }
    })

    // 获取模板相关字段
    const getTemplate = (name) => {
      if (name && state.routeName !== 'updateJournal') {
        state.imageList = []
        state.fileList = []
        // 模板名称和用户id同时匹配
        const index = state.journalList.findIndex(item =>
          item.template_name === name && item.user_id === state.currentUser.id
        )
        if (index > -1) {
          state.template = state.journalList[index]
          initData(state.journalList[index])
        } else {
          getTemplateInfo({ template_name: name }).then(res => {
            if (res.code === 0) {
              if (res.data.template_name === '空白模板') {
                state.template = {
                  template_name: res.data.template_name,
                  template_id: res.data.template_id,
                  user_name: res.data.user_name,
                  fields: [{
                    field_name: '',
                    content: ''
                  }]
                }
              } else {
                state.template = res.data
              }
              // 查询接收人
              getReceiveUser()
              // 删除后台返回的图片和附件字段
              const imageIndex = state.template.fields.findIndex(field => field.field_name === '图片')
              const fileIndex = state.template.fields.findIndex(field => field.field_name === '图片')
              imageIndex > -1 && state.template.fields.splice(imageIndex, 1)
              fileIndex > -1 && state.template.fields.splice(fileIndex, 1)
              // 手动添加图片和附件字段
              state.template.fields.push({ field_name: '图片', type: 'image' })
              state.template.fields.push({ field_name: '附件', type: 'file' })
            } else {

            }
          })
        }
      }
    }

    // 获取日志接收用户
    const getReceiveUser = () => {
      getReceiveList({ template_id: state.template.template_id }).then(res => {
        if (res.code === 0) {
          state.receiveList = []
          res.data.list.forEach(item => {
            if ((item.user_id === state.currentUser.id || item.is_leader === 1)) {
              item.disabled = true
            }
            state.receiveList.push(item)
          })
          store.commit('work/changeReciverList', { template_id: state.template.template_id, list: state.receiveList })
          // state.receiveList.push(...res.data.list)
          // state.receiveList.filter(item => { item.user_id === state.currentUser.id && (item.disabled = true) })
          // 初始化选择用户右侧数据
          state.selectUserRef && state.selectUserRef.initData()
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }
    // 上传文件成功
    const uploadFileInf = (type, file) => {
      if (type === 'image') {
        state.imageInfoRef.uploadFileInf(type, file)
        state.imageList = computed(() => state.imageInfoRef.imageList)
      } else {
        const obj = {
          name: file.name,
          url: file.url,
          uid: file.uid,
          size: file.size
        }
        state.fileList.push(obj)
      }
    }

    // 删除文件
    const removeFile = (uid) => {
      state.fileList = state.fileList.filter(item => item.uid !== uid)
    }

    // 提交日志  type false 仅提交日志 true 提交日志同时打下班卡
    const submit = debounce((type = false) => {
      const data = getData()
      state.isSubmiting = !type
      state.isSubmitingWork = type
      if (state.routeName === 'updateJournal') {
        data.id = store.state.work.journalId
        data.again_notice = state.noticeAgain ? 1 : 0
        update(data)
      } else {
        add(data, type)
      }
    }, 2000)

    // 获取编辑器的内容 组装数据
    const getAllEditor = () => {
      document.querySelectorAll('.journal-item .w-e-text').forEach((item, index) => {
        state.template.fields[index].content = item.innerHTML
      })
    }
    // 组装数据
    const getData = () => {
      const templateName = state.template.template_name
      if (templateName === '空白模板' && !state.template.fields[0].field_name) {
        state.template.fields[0].field_name = `${state.template.user_name}的日志`
      }
      getAllEditor()
      const atUserList = []
      state.template.fields.forEach((i, index) => {
        i.content && atUserList.push(...getAtUserID(i.content))
      })

      const data = {
        template_id: state.template.template_id,
        template_name: templateName,
        fields: state.template.fields && state.template.fields.slice(0, state.template.fields.length - 2),
        to_userids: state.receiveUserIds,
        to_chat: false,
        to_cids: [],
        images: state.imageInfoRef?.imageList.map(item => { return item.url }).join('^'),
        files: state.fileList.map(item => { return { url: item.url, size: item.size, name: item.name } }),
        at_user: atUserList
      }

      return data
    }
    // 保存临时数据
    const saveTempData = () => {
      const data = getData()
      data.receiveList = state.receiveList
      data.user_name = state.currentUser.name
      data.user_id = state.currentUser.id
      store.commit('work/setJournalList', data)
    }
    eventBus.$on('saveLog', () => { !state.isSubmited && route.name === 'writeJournal' && saveTempData() })
    const showSelectUser = () => {
      state.dialogVisible = true
    }

    const hideSelectUser = () => {
      state.dialogVisible = false
      // 重置数据
      state.selectUserRef.resetData()
    }

    // 选择日志接收用户
    const addReceiveUser = () => {
      const disabledUser = state.receiveList.filter(item => item.disabled)
      let disabledUserIds = []
      if (disabledUser) {
        disabledUserIds = disabledUser.map(item => item.ding_user_id) // 不能删除的用户
      }
      state.receiveList = [] // 先清空，重新渲染选择的用户，避免漏删除
      state.selectUserRef.rightData.forEach(item => {
        if (item.dept_id) {
          getTeamUserList({ dept_id: item.dept_id }).then(res => {
            res.data.forEach(user => {
              setReceiveUser(disabledUserIds, user)
            })
          })
        } else {
          setReceiveUser(disabledUserIds, item)
        }
      })
      hideSelectUser()
    }

    // 设置日志接收人是否可删除
    const setReceiveUser = (disabledUserIds, item) => {
      const user = JSON.parse(JSON.stringify(item))
      if (disabledUserIds.includes(item.ding_user_id)) {
        user.disabled = true
      }
      // 去重添加
      !state.receiveUserIds.includes(user.ding_user_id) && state.receiveList.push(user)
    }

    // 移除日志接收人
    const removeUser = (index) => {
      state.receiveList.splice(index, 1)
      state.isSubmitingWork = false
    }

    // 修改日志，初始化日志数据
    const getJournalData = () => {
      getJournalInfo({ id: store.state.work.journalId }).then(res => {
        updateJournalData(res.data)
      })
    }

    // 修改日志数据
    const updateJournalData = (data, type) => {
      state.template = data
      initData(data, type)
    }

    // 导入次数
    let importCount = 0

    const initData = (data, type) => {
      getReceiveUser()
      // 如果是导入进来的数据，则不执行下述逻辑
      if (!type) {
        // 修改日志，接收用户不能修改
        if (state.routeName === 'updateJournal') {
          state.receiveList = data.to_userids
          state.receiveList.forEach(item => {
            item.disabled = true
          })
        } else {
          if (data.receiveList.length > 0) {
            state.receiveList = data.receiveList
            state.receiveList.filter(item => { item.user_id === state.currentUser.id && (item.disabled = true) })
          }
        }
      } else if (type === 'import') {
        const addNull = (content, count) => {
          for (let i = 0; i < count; i++) {
            content += ' '
          }
          return content
        }

        const arr = []
        // 空格超过3位时进行重新0
        importCount = importCount > 3 ? 0 : (importCount + 1)
        state.template.fields.forEach(({ type, content }, index) => {
          if (type !== 'image' && type !== 'file') {
            const fields = state.template.fields[index]
            // 用于触发数据变化，引起视图更新
            fields.content = addNull(fields.content, importCount)
            arr.push(fields)
          }
        })

        state.template.fields = arr
      }

      const index = state.template.fields.findIndex(item => item.type === 'image' || item.type === 'file')
      if (index === -1) {
        state.template.fields.push({ field_name: '图片', type: 'image' })
        state.template.fields.push({ field_name: '附件', type: 'file' })
      }

      if (data.images.length > 0) {
        const imageList = data.images.split('^')
        if (imageList.length > 0) {
          imageList.forEach(item => {
            state.imageList.push({ url: item })
          })
        }
      } else {
        state.imageList = []
      }
      nextTick(() => {
        state.imageInfoRef.imageList = state.imageList
      })

      if (data.files && data.files.length > 0) {
        state.fileList = data.files
      } else {
        state.fileList = []
      }
    }

    const { sendToSocket } = mySocket()

    const reClock = () => {
      setTimeout(() => {
        state.count += 1
        if (state.count < 4) {
          successFun()
        } else {
          store.commit('closeLoading')
          return dialogMsg('error', '打卡失败，请尝试重新打卡')
        }
      }, 1000)
    }

    // 打卡状态
    async function successFun () {
      try {
        const result = await attendanceClock()
        if (result.code === 0) {
          const res = result.data.time_result
          store.commit('closeLoading')
          let clockState
          if (res === 'Early') clockState = 5 // 早退签退打卡
          if (res === 'Absenteeism' && result.data.check_type === 'OnDuty') clockState = 6 // 旷工打卡
          if (res === 'Absenteeism' && result.data.check_type === 'OffDuty') clockState = 7 // 旷工 签退
          if (res === 'Normal' && result.data.check_type === 'OffDuty') clockState = 3

          store.commit('work/setSignTime', result.data.user_check_time)
          store.commit('work/setSignState', clockState)
          eventBus.$emit('openClockDialog')
          // 更新个人状态
          setTimeout(() => {
            store.dispatch('work/getClockState')
            // 更新日历中的打卡状态
            eventBus.$emit('attendanceSuccess')
            // 重置状态
            store.commit('work/setAutoSignOut', 0)
            store.commit('work/setSubmitJournal', 0)
          }, 400)
        } else if (result.code === 70001) {
          store.commit('closeLoading')
          return dialogMsg('error', result.msg)
        } else {
          reClock()
        }
      } catch {
        reClock()
      }
    }

    // 提交日志
    const add = (data, type) => {
      if (type) {
        //  点击提交并签退按钮
        localStorage.setItem('submitLog', +new Date())
        setBuriedPoint({ event_id: 10004, uc: 1 })
        store.commit('openLoading', '提交并打卡中...')
      } else {
        //  点击仅提交日志按钮
        setBuriedPoint({ event_id: 10003, uc: 1 })
      }
      data.fields.forEach(i => (i.content && !i.content.includes('table-box') && (i.content = addTableFatherNode(i.content))))
      addJournal(data).then(res => {
        state.isSubmiting = false
        if (res.code === 0) {
          // 提交日志成功后进行打卡
          if (type) {
            setTimeout(() => {
              successFun()
            }, 1000)
          } else { // 弹出签退弹出
            store.commit('work/setAutoSignOut', 1)
            store.commit('work/setSubmitJournal', 1)
          }
          afterSubmit('add', data, false)
        } else if (res.code === 60003) { // 接收列表有用户离职
          state.leaveUser = res.data
          store.commit('closeLoading')
        } else {
          dialogMsg('error', res.msg)
          store.commit('closeLoading')
        }
        state.isSubmitingWork = false
      }).catch(() => {
        state.isSubmiting = false
        state.isSubmitingWork = false
        store.commit('closeLoading')
      })
    }

    // 更新日志
    const update = (data) => {
      updateJournal(data).then(res => {
        state.isSubmiting = false
        if (res.code === 0) {
          afterSubmit('update', data)
        } else {
          dialogMsg('error', res.msg)
        }
      }).catch(() => {
        state.isSubmiting = false
      })
    }

    const afterSubmit = (type, data, isSuccessToast = true) => {
      isSuccessToast && myToast({ type: 'success', message: `${type === 'add' ? '发布' : '修改'}成功`, forbidClick: true })
      state.isSubmited = true
      store.commit('work/removeJournalList', data)
      if (type === 'add' || data.again_notice === 1) {
        const arr = []
        state.receiveList.forEach(item => {
          // 过滤日志作者，避免更新未读日志
          item.user_id !== state.currentUser.id && arr.push(item.user_id)
        })
        sendToSocket(arr, { type: 6 })
      }
      router.push({ name: 'work' })
      // setTimeout(() => {
      //   router.push({ name: 'work' })
      // }, 3000)
    }
    // 确认移除已离职人员
    const removeReceiveUser = () => {
      state.leaveUser.forEach((user) => {
        const index = state.receiveList.findIndex(item => item.ding_user_id === user.ding_user_id)
        removeUser(index)
      })
      state.leaveUser = []
    }

    // 空白日志标题输入框聚焦
    const focusInput = () => {
      if (state.routeName !== 'updateJournal') {
        state.template.fields[0].field_name = `${state.template.user_name}的日志`
      }
    }

    return {
      ...toRefs(state),
      uploadFileInf,
      removeFile,
      submit,
      showSelectUser,
      hideSelectUser,
      removeUser,
      addReceiveUser,
      removeReceiveUser,
      focusInput,
      importCount,
      updateJournalData
    }
  }
}
</script>

<style lang="less" scoped>
  .write-journal-box{
    padding: 60px 70px 63px 70px;
    color: @default-text-color;
    background: #fff;
    .name{
      margin-bottom: 30px;
    }
    .name, .journal-item .title :deep(.el-textarea__inner){
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      color: @default-text-color;
    }
    .journal-item{
      margin-top: 42px;
    }
    :deep(.file-list) {
      z-index: 0;
    }
  }
  .el-textarea{
    border: none;
    :deep(.el-textarea__inner){
      resize: none;
      border: none;
      width: 100%;
      min-height: 200px;
      padding: 0;
      font-size: 14px;
      line-height: 25px;
      color: @default-text-color;
    }
  }
  .title, .receive-wraper .desc{
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
  }
  .content {
    padding-top: 8px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .receive-wraper{
    margin: 36px 0 64px;
    border-top: 1px solid #F1F1F1;
    .desc{
      margin: 36px 0 16px;
    }
    .receive-list{
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .el-icon-plus{
        border-radius: 50%;
      }
      .user-avatar{
        margin-bottom: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 29px;
        &:nth-child(10n+10){
          margin-right: 0;
        }
        span{
          margin-top: 6px;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  .button-wraper{
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    .el-button + .el-button {
      margin-top: 15px;
      margin-left: 0;
    }
  }
  .user-avatar{
    position: relative;
    .remove-icon{
      position: absolute;
      right: -10px;
      top: -5px;
      cursor: pointer;
      z-index: 2;
      &:hover circle{
        fill: @active-text-color;
        opacity: 1;
      }
    }
  }
  .el-button{
    width: 306px;
    height: 44px;
    border-radius: 33px;
  }
  .el-icon-plus{
    width: 74px;
    height: 74px;
    background: #F6F9FA;;
    cursor: pointer;
    &::before{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 27px;
      color: #C4C4C4;
      transform: scale(1.9);
    }
    &.mini{
      width: 38px;
      height: 38px;
      &::before{
        margin-top: 10px;
        transform: scale(1.0);
      }
    }
  }
  .button-box{
    display: flex;
    justify-content: flex-end;
    .el-button{
      width: 85px;
      height: 32px;
    }
    .el-button:first-child{
      background: #E4E3FC;
      border-radius: 30px;
      color: #7972F0;
    }
  }
  .el-checkbox{
    color: @default-text-color;
  }
  :deep(.el-checkbox__inner){
    background: #F6F9FA;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    font-size: 13px;
    line-height: 17px;
    margin-left: 8px;
    &:hover{
      border:none;
    }
    &::after {
      border-width: 2px;
      left: 6px;
      top: 2px;
    }
  }
  :deep(.el-checkbox__input.is-checked+.el-checkbox__label){
   color: @default-text-color;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    background-color: @active-text-color;
    border-color: @active-text-color;
  }
  :deep(.receiver-dialog){
    width: 361px !important;
    height: 140px !important;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 24px 30px;
      font-size: 14px;
      line-height: 18px;
      text-align: justify;
      color: @default-text-color;
      .name{
        font-size: 14px;
        line-height: 18px;
        color: @active-text-color;
      }
    }
    .el-dialog__footer{
      padding: 0;
      .el-button{
        width: 100px;
        height: 32px;
        background: @active-text-color;
        border-radius: 51px;
        color: #FFFFFF;
      }
    }
  }
</style>

<style lang="less">
  .select-user-dialog{
    background: #F6F9FA;
    border-radius: 6px;
    .el-dialog__body{
      padding: 6px 24px;
      font-size: 13px;
      color: @default-text-color;
    }
    .el-dialog__title{
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
    }
    .el-button{
      font-size: 14px;
    }
  }
  .content {
    outline: none !important;
    border: none !important;
    word-break: break-all;
    div {
          outline: none !important;
        border: none !important;
    }
  }
  .el-scrollbar {
    overflow: unset;
  }
</style>
