<!--
 * @Descripttion: 日志模板
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-17 15:51:56
 * @LastEditors: heqi
 * @LastEditTime: 2022-05-25 17:31:50
-->
<template>
  <div class="border-6 scroll-bar-tpl">
    <p class="template-title oneline-overflow">{{template.template_name}}</p>
    <div v-for="(data, index) in template.fields" :key="data.id">
      <template v-if="index < 3">
        <p class="template-field"> {{data.field_name}}</p>
        <p class="template-content">请输入</p>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    template: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="less" scoped>
  .template-title{
    font-size: 16px;
    color: @default-text-color;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .template-field,.template-content{
    font-size: 10px;
  }
  .template-field{
    margin-bottom: 4px;
  }
  .template-content{
    margin-bottom: 12px;
    color: @second-text-color;
  }
</style>
