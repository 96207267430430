<!--
 * @Description: 写日志编辑器
 * @Author: heqi
 * @Date: 2022-03-11 16:52:04
 * @LastEditTime: 2022-11-22 10:38:37
 * @LastEditors: heqi
 * @FilePath: \mfzj\src\views\layout\work\writeJournal\component\WriteEditorJournal.vue
-->
<template>
  <div ref='editor' @keydown="enterEv($event)" class="editor-el">
  </div>
  <el-input v-show="false" v-model="value"></el-input>
  <ul class="at-wrapper scroll-bar" v-show="showUserList">
    <el-input v-model.trim="searchKeyword" placeholder="请输入姓名" @input="handleSearchInput" @keydown="handleKeyDown" class="search-input" ref="searchInputRef" />
    <template v-if="filterList.length">
      <li class="search-user" v-for="employee in filterList" :key="employee.id" @click="selectPerson(employee)">{{employee.value}}</li>
    </template>
    <template v-else>
      <p class="no-search">没有搜索到相关用户...</p>
    </template>
  </ul>
</template>
<script>
import WangEditor from 'wangeditor'
import { onMounted, onBeforeUnmount, ref, watch, reactive, computed, toRefs, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { position } from 'caret-pos'
// import '@/utils/formatBrush.js'

export default {
  name: 'WriteJournalEditor',
  props: ['content', 'id'],
  setup (props, { emit }) {
    const editor = ref()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      searchInputRef: null,
      searchKeyword: '',
      showUserList: false,
      userList: computed(() => store.state.userList),
      filterList: store.state.userList,
      myposition: {
        range: '',
        selection: ''
      },
      left: '',
      top: '',
      value: '',
      isUp: false
    })

    let instance

    onMounted(() => {
      instance = new WangEditor(editor.value)
      instance.config.onchangeTimeout = 500
      Object.assign(instance.config, {
        onchange (newHtml) {
          state.isUp = true
        },
        onfocus () {
          document.querySelectorAll('.w-e-toolbar').forEach(i => (i.style.display = 'none'))
          instance.$toolbarElem.elems[0].style.display = 'flex'
        },
        onSelectionChange (newSelection) {
          if (newSelection.selection.editor.$toolbarElem.elems[0].style.display === 'flex') return
          document.querySelectorAll('.w-e-toolbar').forEach(i => (i.style.display = 'none'))
          newSelection.selection.editor.$toolbarElem.elems[0].style.display = 'flex'
        }
      })
      // 去除菜单按钮
      instance.config.excludeMenus = [
        // 'head', // 标题
        // 'bold', // 粗体
        // 'fontSize', // 字号
        'fontName', // 字体
        // 'italic', // 斜体
        // 'underline', // 下划线
        // 'strikeThrough', // 删除线
        // 'foreColor', // 文字颜色
        // 'backColor', // 背景颜色
        'link', // 插入链接
        // 'list', // 列表
        // 'justify', // 对齐方式
        'image', // 插入图片
        'video', // 插入视频
        'quote', // 引用
        // 'table', // 表格
        'code', // 插入代码
        // 'undo', // 撤销
        // 'redo', // 重复
        'todo',
        'indent',
        'quote',
        'emoticon'
      ]
      instance.config.showFullScreen = false
      instance.config.menuTooltipPosition = 'down'
      instance.config.lineHeights = ['1', '1.15', '1.6', '2', '2.5', '3']
      instance.config.fontNames = [
        '黑体',
        '仿宋',
        '楷体',
        '标楷体',
        '华文仿宋',
        '华文楷体',
        '宋体',
        '微软雅黑',
        'Arial',
        'Tahoma',
        'Verdana',
        'Times New Roman',
        'Courier New'
      ]
      instance.config.fontSizes = {
        'x-small': { name: '12px', value: '1' },
        small: { name: '14px', value: '2' },
        normal: { name: '16px', value: '3' },
        large: { name: '18px', value: '4' },
        'x-large': { name: '24px', value: '5' },
        'xx-large': { name: '32px', value: '6' },
        'xxx-large': { name: '48px', value: '7' }
      }
      instance.config.focus = false
      instance.config.placeholder = '请输入'
      // instance.config.pasteFilterStyle = false
      instance.config.pasteIgnoreImg = true
      instance.config.zIndex = 0
      instance.config.menuTooltipPosition = 'down'
      instance.create()
      // 切换模板 监听数据变化
      watch(() => props.content, (newValue) => {
        instance.txt.html(newValue || '')
      }, { immediate: true })

      watch(() => state.value, (v) => {
        if (v) {
          if (state.isUp) {
          // 这里通过标识符避免重复触发事件
            state.isUp = false
          } else {
            nextTick(() => {
              instance.txt.html(v)
            })
          }
        }
      }, { immediate: true })

      // 监听路由修改样式
      watch(() => route.name, (newVal) => {
        if (newVal === 'updateJournal') {
          instance.$toolbarElem.elems.forEach(i => {
            i.style.left = '0'
            i.style.right = 'auto'
            i.style.paddingLeft = '20' + 'px'
          })
        }
      }, {
        immediate: true,
        deep: true
      })
      const writeJournalDom = document.querySelectorAll('.w-e-toolbar')
      writeJournalDom.forEach(i => (i.style.display = 'none'))
      writeJournalDom[0].style.display = 'flex'

      // 监听插入的元素 通过 MutationObserver 来监听元素变化 可监听子节点的插入与删除 以及属性变化 class等
      instance.$toolbarElem.elems[0].querySelectorAll('.w-e-menu').forEach(i => {
        if (i.getAttribute('data-title') === '表格') return

        // 第一次点击tab菜单 隐藏除自身以外菜单
        i.addEventListener('click', (e) => {
          instance.$toolbarElem.elems[0].querySelectorAll('.w-e-menu').forEach(j => {
            if (j.getAttribute('data-title') !== i.getAttribute('data-title')) {
              j.querySelector('.w-e-droplist') && (j.querySelector('.w-e-droplist').style.visibility = 'hidden')
            }
          })
        })

        // 监听节点变化修改显示和隐藏关系
        const observer = new MutationObserver(mutationsList => {
          for (const mutation of mutationsList) {
            // 插入或删除子节点
            if (mutation.type === 'childList') {
              // 只在第一次修改display 并监听
              if (mutation.target.querySelectorAll('.w-e-droplist').length < 2) {
                // 修改自带的mouseleave
                mutation.addedNodes[0].addEventListener('mouseleave', () => {
                  setTimeout(() => {
                    mutation.addedNodes[0].style.display = 'block'
                  })
                })
                mutation.target.addEventListener('mouseleave', () => {
                  setTimeout(() => {
                    mutation.addedNodes[0].style.display = 'block'
                  })
                })
                // 菜单选项点击事件
                mutation.target.addEventListener('click', () => {
                  if (mutation.addedNodes[0].style.visibility === 'hidden') {
                    document.querySelectorAll('.w-e-droplist').forEach(j => {
                      j.style.visibility = 'hidden'
                    })
                    mutation.addedNodes[0].style.visibility = 'visible'
                  } else {
                    mutation.addedNodes[0].style.visibility = 'hidden'
                  }
                })
                // 二级菜单点击事件
                mutation.addedNodes[0].querySelectorAll('li').forEach(i => {
                  i.addEventListener('click', () => {
                    mutation.addedNodes[0].style.visibility = 'hidden'
                  })
                })
              }
            }
          }
        })

        observer.observe(i, { childList: true })
      })

      document.addEventListener('click', (e) => {
        // console.log(e.target.className)
        const showClassName = ['search-user', 'el-input__inner', 'at-wrapper scroll-bar']
        if (!showClassName.includes(e.target.className)) {
          state.showUserList = false
          state.searchKeyword = ''
          handleSearchInput()
        }
        // w-e-droplist
        if (document.querySelector('.w-e-droplist')) {
          document.querySelectorAll('.w-e-droplist').forEach(i => {
            i.style.visibility = 'hidden'
          })
        }
      })
    })
    onBeforeUnmount(() => {
      instance.destroy()
      instance = null
      document.removeEventListener('click', (e) => {
        console.log(e)
      })
    })

    /**
     * @description keydown触发事件
     * @param e
     */
    const enterEv = (e) => {
      const selection = getSelection()
      // 判断输入的为@符号
      if ((e.key === '@' || e.code === 'Digit2') && e.shiftKey) {
        state.myposition = {
          range: selection.getRangeAt(0), // 获取选区区域的引用
          selection: selection
        }
        // 根据输入@的位置定位下拉框的位置
        getPosition()
        setTimeout(() => {
          // 下拉框搜索框自动获取焦点
          nextTick(() => {
            state.searchInputRef.focus()
          })
        })
        // 初始时，编辑器中元素是<p><br></p>
        const brDom = instance.$textElem.elems[0].firstChild.firstChild
        if (brDom && brDom.tagName === 'BR') {
          // 移除br
          instance.$textElem.elems[0].firstChild.removeChild(brDom)
        }
      }
    }

    /**
     * @description 获取光标位置
     */
    const getPosition = () => {
      state.showUserList = true
      // 获取编辑器节点
      const ele = instance.$textElem.elems[0]
      // 获取位置
      const pos = position(ele)
      // 离职审批的通过评论@位置不同
      // 定位下拉框位置
      state.left = pos.left + 70
      state.top = pos.top + 190
    }

    const handleSearchInput = (e) => {
      // console.log(state.searchKeyword)
      const newData = state.userList.filter(i => i.value.includes(state.searchKeyword))
      state.filterList = newData
    }

    const handleKeyDown = (e) => {
      if (e.key === 'enter') {
        e.preventDefault ? e.preventDefault() : e.returnValue = false
      }
    }

    const selectPerson = ({ value, id }) => {
      state.showUserList = false
      // 重置状态
      resetStatus()
      // 获取选区对象
      // eslint-disable-next-line prefer-const
      let selection = state.myposition.selection
      // eslint-disable-next-line prefer-const
      let range = state.myposition.range
      const nodeAt = document.createElement('a')
      const spanNode2 = document.createElement('span')
      nodeAt.className = 'at-text'
      nodeAt.style.color = '#7972F0'
      nodeAt.innerHTML = '@' + value
      nodeAt.dataset.id = id
      nodeAt.dataset.isatcompanyuserid = id
      //  设置@人的节点不可编辑
      // nodeAt.contentEditable = 'false'
      spanNode2.innerHTML = '&nbsp'
      nodeAt.setAttribute('href', `/personalCenter?id=${id}`)
      // 将生成内容打包放在 Fragment 中，并获取生成内容的最后一个节点，也就是空格。
      // 创建一个新的空白的文档片段
      const frag = document.createDocumentFragment(); let node; let lastNode
      frag.appendChild(nodeAt)
      instance.cmd.do('delete')
      // eslint-disable-next-line prefer-const
      node = spanNode2.firstChild
      if (node) {
        lastNode = frag.appendChild(node)
      }
      // 将 Fragment 中的内容放入 range 中，并将光标放在空格之后。
      range.insertNode(frag)
      selection.collapse(lastNode, 1)
      // 将当前的选区折叠到最末尾的一个点
      selection.collapseToEnd()
    }

    const resetStatus = () => {
      state.searchKeyword = ''
      state.filterList = store.state.userList
    }

    const getHtml = () => {
      return instance && instance.txt.html()
    }
    return {
      editor,
      ...toRefs(state),
      enterEv,
      handleSearchInput,
      handleKeyDown,
      selectPerson,
      getHtml
    }
  }
}
</script>

<style scoped lang="less">
@import url(../../../../../assets/less/wangeditor.less);
.at-wrapper {
  position: absolute;
  z-index: 9999;
  max-height: 300px;
  overflow: auto;
  background: #feffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);;
  border-radius: 4px;
  width: fit-content;
  padding: 10px;
  .search-input {
    width: 100%;
    margin-bottom: 10px;
  }
  li {
    padding: 4px 10px;
    padding-left: 18x;
    border-radius: 15px;
    font-size: 14px;
  }
  li:hover {
    color: #7972f0;
    background: rgba(121, 114, 240, 0.2);
  }
}
.no-search {
  font-size: 12px;
  color: #999;
}

:deep(.el-icon-s-open) {
  color: #999;
}
:deep(.w-e-active) {
  color: @active-text-color;
}
</style>
